import React, { useState } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import "./popper.scss";

export const CustomPopper = ({
  open,
  anchorEl,
  position,
  handleClose,
  ...props
}) => {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      className="custom-popper"
      open={open}
      anchorEl={anchorEl}
      placement={position}
      {...props}
      modifiers={[
        {
          name: "flip",
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: false,
            tether: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {open ? <span className="popper-arrow" ref={setArrowRef} /> : null}
      <ClickAwayListener onClickAway={handleClose}>
        <Box>{props.children}</Box>
      </ClickAwayListener>
    </Popper>
  );
};

export const propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  position: PropTypes.oneOf([
    "top-start",
    "top",
    "top-end",
    "left-start",
    "left",
    "left-end",
    "right-start",
    "right",
    "right-end",
    "bottom-start",
    "bottom",
    "bottom-end",
  ]),
};

const defaultProps = {
  open: false,
  position: "bottom",
};

CustomPopper.propTypes = propTypes;
CustomPopper.defaultProps = defaultProps;
