import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import "./input.scss";
import PropTypes from "prop-types";
import { CustomPopper } from "../Popper/Popper";
import InfoIcon from "@mui/icons-material/Info";
import classNames from "classnames";
import Box from "@mui/material/Box";
import Label from "../Label/Label";
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
import { CustomTooltip } from "../Tooltip/Tooltip";
import { loaderSpinner } from "../images";
import Text from "../Text/Text";

export const Input = ({
  value,
  showLabel,
  error,
  label,
  actionLabel,
  actionLabelClick,
  actionLabelClass = "",
  actionLabelLoaderComponent = null,
  actionLabelLoader = false,
  fullWidth,
  customClass,
  isIcon,
  iconContent,
  isPasswordVisible,
  isFromDetailPage,
  isTextVisible,
  inputText,
  ...props
}) => {
  const [inputType, setInputType] = useState("password");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      actionLabelClick();
    }
  };

  const open = Boolean(anchorEl);
  const classes = classNames(
    "input-element",
    isPasswordVisible && "password-input",
    customClass
  );

  const renderWithIcon = () => {
    return isIcon ? (
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Label>{label}</Label>
        <InfoIcon className="label-with-icon" onClick={handleClick} />
        <CustomPopper
          disablePortal={true}
          open={open}
          anchorEl={anchorEl}
          position="top"
          handleClose={handleClose}
        >
          {iconContent}
        </CustomPopper>
      </Box>
    ) : (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Label>{label}</Label>
        {actionLabelLoader
          ? actionLabelLoaderComponent
          : actionLabel && (
              <Label
                className={`action-label ${actionLabelClass}`}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onClick={() => actionLabelClick()}
              >
                {actionLabel}
              </Label>
            )}
      </Grid>
    );
  };

  const toggleShowPassword = () => {
    if (inputType === "password") {
      if (props?.from === "withdrawalLink" && value === "dummyVal") {
        props?.handleShowPassword("eye-icon").then((res) => {
          if (res) setInputType("text");
        });
      } else {
        setInputType("text");
      }
    } else {
      setInputType("password");
    }
  };

  const renderValue =
    props?.from === "withdrawalLink" && inputType !== "text" && isFromDetailPage
      ? value.slice(0, 8)
      : value;

  return (
    <Box className={classes} sx={{ width: 1 }}>
      {showLabel && renderWithIcon()}
      <InputBase
        {...props}
        type={isPasswordVisible ? inputType : props.type}
        value={renderValue}
        error={error}
        fullWidth={fullWidth}
        endAdornment={
          isTextVisible ? (
            <InputAdornment
              style={{ position: "absolute", right: "14px" }}
              position="end"
            >
              <Text
                className="grey-text"
                font="regular"
                size={16}
                variant="body1"
              >
                {inputText}
              </Text>
            </InputAdornment>
          ) : (
            isPasswordVisible &&
            value && (
              <InputAdornment
                className="password-visibility-icon"
                position="end"
              >
                {props?.passwordLoader ? (
                  <img
                    src={loaderSpinner}
                    alt="Loader"
                    width={15}
                    height={15}
                  />
                ) : (
                  <CustomTooltip
                    arrow={false}
                    placement={props.position || "right"}
                    text={
                      <>{inputType === "text" ? "Hide" : "Show "} password</>
                    }
                  >
                    {inputType === "text" ? (
                      <VisibilityOffIcon onClick={toggleShowPassword} />
                    ) : (
                      <VisibilityIcon onClick={toggleShowPassword} />
                    )}
                  </CustomTooltip>
                )}
              </InputAdornment>
            )
          )
        }
      />
    </Box>
  );
};

export const propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  isPasswordVisible: PropTypes.bool,
  isTextVisible: PropTypes.bool,
  inputText: PropTypes.string,
};

const defaultProps = {
  label: "Input Label",
  showLabel: true,
  disabled: false,
  error: false,
  value: "",
  fullWidth: false,
  name: "",
  isPasswordVisible: false,
  isTextVisible: false,
  inputText: "BTC",
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
