import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import "./modal.scss";
import Text from "../Text/Text";
import AlertMessage from "../AlertMessage/AlertMessage";
import { addDomainContentMsg } from "../messages";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, closeIcon, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {closeIcon ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export const Modal = ({
  handleClose,
  open,
  maxWidth,
  title,
  body,
  footer,
  closeIcon,
  className,
  isStickyContent,
  isError,
  errorMsg,
  titleTextProps = {},
  ..._props
}) => {
  const classes = classNames("modal", className);
  return (
    <Dialog
      onClose={closeIcon ? null : handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      className={classes}
      fullWidth
      disableEscapeKeyDown
      {..._props}
    >
      {title && (
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          closeIcon={closeIcon}
        >
          <Text
            className="default-text"
            size={18}
            font="semibold"
            variant="body1"
            {...titleTextProps}
          >
            {title}
          </Text>
        </BootstrapDialogTitle>
      )}
      {isStickyContent && (
        <>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className="fixed-content"
            closeIcon={closeIcon}
          >
            <Text
              size={14}
              font="regular"
              className="default-text"
              variant="inline"
            >
              {addDomainContentMsg}
            </Text>
            {isError && (
              <AlertMessage
                className="margin-top14"
                severity="error"
                message={errorMsg}
              />
            )}
          </BootstrapDialogTitle>
        </>
      )}
      <DialogContent dividers>{body}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
};

export const propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  closeIcon: PropTypes.bool.isRequired,
};

const defaultProps = {
  open: false,
  maxWidth: "xs",
  closeIcon: true,
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
