import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CustomTooltip } from "../Tooltip/Tooltip";
import "./select.scss";

function CustomSelect({
  value,
  placeholder,
  customClass,
  showLabel,
  className,
  showLabelInfoIcon,
  labelInfoTooltipContent,
  setCustomIcon = false,
  menuClass,
  ...props
}) {
  const handleKeyPress = (e) => {
    if (e.nativeEvent.keyCode === 13 && props.onEnterPress) {
      props.onEnterPress();
    }
  };

  return (
    <Box className={customClass}>
      <Box display="flex">
        {showLabel && (
          <label className="input-with-dropdown-label">{props.label}</label>
        )}
        {showLabelInfoIcon && labelInfoTooltipContent && (
          <CustomTooltip
            arrow={true}
            placement="top"
            text={labelInfoTooltipContent}
            className="custom-tooltip payments-status-tooltip"
          >
            <Box
              sx={{
                width: "fit-content",
                boxShadow: "none",
                marginLeft: "6px",
                position: "relative",
                top: "2px",
              }}
            >
              <InfoIcon
                style={{
                  height: 20,
                  width: 20,
                  color: "#848b9e",
                  cursor: "pointer",
                }}
              />
            </Box>
          </CustomTooltip>
        )}
      </Box>

      <Select
        name={props.name}
        className={classNames("select", className)}
        displayEmpty
        value={value}
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
        onChange={(e) => {
          props.onChange(e);
        }}
        IconComponent={(_props) => (
          <>
            {setCustomIcon ? (
              setCustomIcon(_props)
            ) : (
              <KeyboardArrowDownIcon
                style={{
                  width: 21,
                  height: 25,
                  color: _props.disabled ? "rgba(0,0,0,0.26)" : "#848B9E",
                }}
                {..._props}
              />
            )}
          </>
        )}
        MenuProps={{ className: menuClass }}
        {...props}
      />
    </Box>
  );
}

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomSelect.propTypes = propTypes;

export default CustomSelect;
