import DeactivatedLink from "@speed/common/src/components/DeactivatedLink";
import NotFoundLink from "@speed/common/src/components/NotFoundLink";
import UnderMaintenancePage from "@speed/common/src/components/UnderMaintenancePage";
import { Switch, Route } from "react-router-dom";
import BuyPage from "./Buy";

const Main = () => {
  return (
    <Switch>
      <Route exact path="/:id" component={BuyPage} />
      <Route
        exact
        path="/error/under-maintenance"
        component={UnderMaintenancePage}
      />
      <Route exact path="/error/not-found-link" component={NotFoundLink} />
      <Route exact path="/error/deactivated-link" component={DeactivatedLink} />
      <Route
        path="/"
        component={() => {
          window.location.href = process.env.REACT_APP_PROMO_WEBSITE;
          return null;
        }}
      />
    </Switch>
  );
};

export default Main;
