import { initializeApp } from "firebase/app";
// import {
//   getMessaging,
//   getToken,
//   onMessage,
//   deleteToken,
// } from "firebase/messaging";
// import { sessionService } from "redux-react-session";
import firebase from "firebase/compat/app";
// import { callAPIInterface } from "../components/constants";

export const app =
  !firebase.apps.length &&
  initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_JSON));

// let fcmRegistration;
// function messageCall() {
//   const db = indexedDB.open("test");
//   db.onerror = function () {
//     return true;
//   };
//   db.onsuccess = function () {
//     messaging = getMessaging();
//   };
// }
// let messaging;
// messageCall();

// export const serviceWorkerRegistration = () => {
//   if ("serviceWorker" in navigator) {
//     const sw = navigator.serviceWorker;
//     if (document.readyState === "complete") {
//       registerServiceWorker(sw);
//     } else {
//       window.addEventListener("load", () => {
//         registerServiceWorker(sw);
//       });
//     }
//   }
// };

// const registerServiceWorker = (sw) => {
//   sw.register(
//     `./${process.env.REACT_APP_ENVIRONMENT}/firebase-messaging-sw.js`,
//     { updateViaCache: "none" }
//   )
//     .then(async (registration) => {
//       fcmRegistration = registration;
//       requestForToken(registration);
//     })
//     .catch((error) => console.log("Service worker registration error", error));
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging &&
//       onMessage(messaging, (payload) => {
//         console.log("payload", payload);
//         resolve(payload);
//       });
//   });

// export const requestForToken = async (registration) => {
//   const session = await sessionService.loadSession();
//   return (
//     messaging &&
//     getToken(messaging, {
//       serviceWorkerRegistration: registration,
//       vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
//     })
//       .then(async (currentToken) => {
//         if (currentToken) {
//           if (!session.fcm_token) {
//             const data = {
//               session: session.session,
//               fcm_device_token: currentToken,
//             };
//             await callAPIInterface(
//               "POST",
//               "/device-token",
//               JSON.stringify(data)
//             );
//             sessionService.saveSession({ fcm_token: currentToken, ...session });
//           }
//         } else {
//           console.log(
//             "No registration token available. Request permission to generate one."
//           );
//         }
//       })
//       .catch((err) => {
//         console.log("An error occurred while retrieving token. ", err);
//       })
//   );
// };

// export const deleteFCMToken = async () => {
//   await deleteToken(messaging, {
//     serviceWorkerRegistration: fcmRegistration,
//   }).catch((err) => console.log("Error occurred in Delete FCM Token", err));
// };
