import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import './text.scss';

export const Text = ({ withIcon, className, ...props }) => {
    const classes = classNames('text-wrapper', withIcon && withIcon + ' flex-text ', props.size && 'font-' + props.size, props.font && 'font-' + props.font, className);
    return (<Typography {...props} className={classes}>{props.children}</Typography>)
}

const propTypes = {
    children: PropTypes.any.isRequired,
    align: PropTypes.string,
    variant: PropTypes.string,
    font: PropTypes.string,
    size: PropTypes.number,
    withIcon: PropTypes.string,
    className: PropTypes.string
}

const defaultProps = {
    align: 'left',
    font: 'semibold',
    variant: 'h5',
    size: 24,
    withIcon: ''
}

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;