import React from "react";
import { bottomCircle, leftCircle, rightCircle } from "../components/images";

const BackgroundImage = (props) => {
  return (
    <>
      <img className="bottom-image" src={bottomCircle} />
      <img className="right-image" src={rightCircle} />
      <img className="left-image" src={leftCircle} />
    </>
  );
};

export default BackgroundImage;
