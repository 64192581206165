import { notFoundGif } from "./images";
import CommonCheckoutActions from "./CommonCheckoutActions";
import { useLocation } from "react-router-dom";

const NotFoundLink = () => {
  const url = useLocation();

  const showMessage = () => {
    switch (url?.state?.from) {
      case "withdraw":
        return "withdrawLinkNotFound";
      case "invoice":
        return "invoiceNotFound";
      case "checkout":
      default:
        return "urlNotFound";
    }
  };
  return (
    <CommonCheckoutActions
      action="error"
      actionMainText="linkNotFound"
      actionGif={notFoundGif}
      actionSubText={showMessage()}
    />
  );
};

export default NotFoundLink;
