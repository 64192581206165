import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { useState } from "react";

function TruncatedTextTooltip({
  textValue,
  cellWidth,
  textProps,
  boxProps,
  ...props
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = (event) => {
    let element = event.target;
    setShowTooltip(element.offsetWidth < element.scrollWidth);
  };

  return (
    <CustomTooltip
      {...props}
      text={<>{textValue}</>}
      position="top"
      disableHoverListener={!showTooltip}
    >
      <Box
        style={{ maxWidth: cellWidth }}
        onMouseEnter={(event) => handleMouseEnter(event)}
        {...boxProps}
      >
        <Text
          size={16}
          font="regular"
          variant="subtitle1"
          noWrap
          {...textProps}
        >
          {textValue}
        </Text>
      </Box>
    </CustomTooltip>
  );
}

export default TruncatedTextTooltip;
