import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./textarea.scss";
import PropTypes from "prop-types";
import Label from "../Label/Label";
import classNames from "classnames";

export const TextAreaComponent = ({
  value,
  showLabel,
  error,
  label,
  maxRows,
  minRows,
  placeholder,
  fullWidth,
  disabled = false,
  resize,
  customClass = "",
  labelSx = {},
  ...props
}) => {
  const styles = props.style || {};

  return (
    <>
      {showLabel && <Label sx={labelSx}>{label}</Label>}
      <TextareaAutosize
        {...props}
        aria-label={label || "textarea"}
        disabled={disabled}
        className={classNames(
          "text-area",
          error ? "text-area-error" : "",
          disabled ? "text-area-disabled" : "",
          !resize ? "text-area-stop-resize" : "",
          fullWidth && "full-width",
          customClass
        )}
        maxRows={maxRows}
        minRows={minRows}
        placeholder={placeholder}
        value={value}
        style={styles}
      />
    </>
  );
};

export const propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  resize: PropTypes.bool,
};

const defaultProps = {
  showLabel: true,
  maxRows: 4,
  minRows: 2,
  error: false,
  value: "",
};

TextAreaComponent.propTypes = propTypes;
TextAreaComponent.defaultProps = defaultProps;
