import React from "react";
import { Box } from "@mui/material";
import Text from "./Text/Text";
import Translation from "./Translation";

const CommonCheckoutActionContent = (props) => {
  return (
    <>
      <Box className="action-body">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={props?.actionGif}
            className="action-img"
            alt={props?.action}
            height="100px"
            width="100px"
          />
        </Box>
        <Box className="action-text">
          <Text className="action-main-text" font="semibold" align="center">
            <Translation value={props?.actionMainText} />
          </Text>
          <Text
            className="action-sub-text grey-text"
            font="regular"
            align="center"
            variant="subtitle1"
          >
            <Translation value={props?.actionSubText} />
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default CommonCheckoutActionContent;
