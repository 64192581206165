import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import classNames from "classnames";
import { buttonIcons } from "../images";
import "./button.scss";

export const Button = ({ label, variant, className, ...props }) => {
  let classes = "";
  if (props.icon) {
    classes = classNames("button-with-icon", "button-wrapper", className);
    if (props.iconPosition === "start")
      props.startIcon = buttonIcons[props.icon];
    else props.endIcon = buttonIcons[props.icon];
  } else {
    classes = classNames("button-wrapper", className);
  }

  // Deleting this prop, as we got React does not recognize the `iconPosition` prop on a DOM element error
  delete props.iconPosition;

  return (
    <LoadingButton
      variant={variant}
      className={classes}
      color={props.color}
      {...props}
    >
      {" "}
      <div className={props.textClass || ""} style={{ margin: "auto" }}>
        {label}
      </div>{" "}
    </LoadingButton>
  );
};

const propTypes = {
  variant: PropTypes.oneOf(["outlined", "contained", "default"]),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit"]),
  iconPosition: PropTypes.oneOf(["start", "end"]),
  icon: PropTypes.oneOf([
    "google",
    "magicLink",
    "help",
    "signoutIcon",
    "addIcon",
    "closeIcon",
    "checkIcon",
    "filterIcon",
    "visibilityIcon",
    "sendIcon",
    "editIcon",
    "chatIcon",
    "calenderIcon",
    "emailIcon",
    "rightArrowIcon",
    "filterList",
    "calenderIcon",
    "arrowDownIcon",
    "arrowOutwardIcon",
    "expandCircleIcon",
  ]),
};

const defaultProps = {
  variant: "contained",
  label: "Button",
  loading: false,
  disabled: false,
  color: "primary",
  type: "button",
  iconPosition: "start",
  fullWidth: false,
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
