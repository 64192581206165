import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import "./avatar.scss";
import classNames from "classnames";
import Skeleton from "@mui/material/Skeleton";
import { brokenImage, defaultUser } from "../images";

export const CustomAvatar = ({
  userName,
  isLoading,
  size,
  src,
  className,
  ...props
}) => {
  if (isLoading) {
    return <Skeleton className={size} variant="circular" animation="wave" />;
  } else {
    return (
      <Avatar
        {...props}
        src={src ? src : defaultUser}
        alt={userName ? userName : null}
        className={classNames(`${size}`, className)}
      >
        <img
          alt="default-user-fallback-image"
          src={brokenImage}
          style={{ width: "inherit", height: "inherit" }}
        />
      </Avatar>
    );
  }
};

export const propTypes = {
  userName: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large"]),
  src: PropTypes.string,
};

const defaultProps = {
  isLoading: false,
  size: "small",
};

CustomAvatar.propTypes = propTypes;
CustomAvatar.defaultProps = defaultProps;
