import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const CommonSkeleton = ({ variant, height, width, color, sx, className }) => {
    return (
        <Skeleton
            variant={variant || 'text'}
            animation="wave"
            height={height || 21}
            width={width || 96}
            sx={{ backgroundColor: `${color || ''} !important`, ...sx }}
            className={className}
        />
    );
}

export default CommonSkeleton;