import React from "react";
import { Grid, Box } from "@mui/material";
import CommonCheckoutActionContent from "./CommonCheckoutActionContent";
import classNames from "classnames";

const CommonCheckoutActions = (props) => {
  const webVersion = () => {
    return (
      <Box className="checkout-web-preview">
        <Box className="content-center" height="100vh">
          <Box flexDirection="column" className="content-center">
            <CommonCheckoutActionContent {...props} />
          </Box>
        </Box>
      </Box>
    );
  };

  const mobileVersion = () => {
    return (
      <Box
        className={classNames(
          "checkout-actions-class",
          "content-center",
          "checkout-mobile-preview"
        )}
        sx={{ display: "flex !important" }}
      >
        <Grid className="checkout-page-wrapper withdraw-page-wrapper">
          <CommonCheckoutActionContent {...props} />
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {webVersion()}
      {mobileVersion()}
    </>
  );
};

export default CommonCheckoutActions;
