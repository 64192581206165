import React, { useState } from "react";
import { CustomAutoComplete } from "./AutoComplete/AutoComplete";
import { otherLabel } from "./messages";
import CustomDivider from "./Divider/Divider";
import Text from "./Text/Text";
import { enterWalletName, otherWallet, selectWalletLabel } from "../messages";
import { Input } from "./Input/Input";

const WalletsWithOtherInput = ({
  walletData,
  setFieldValue,
  name,
  value,
  otherWalletValue,
  label,
  sx = {},
  from = "feedback",
}) => {
  const [open, setOpen] = useState(false);
  const [isOtherWallet, setIsOtherWallet] = useState(false);
  return (
    <>
      <CustomAutoComplete
        open={open}
        customClass="wallets-custom-autocomplete"
        name={name}
        options={walletData}
        getOptionLabel={(value) => {
          return value === "other" ? otherLabel : value.name;
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(_e, value) => {
          setIsOtherWallet(false);
          setOpen(false);
          setFieldValue(name, value);
        }}
        value={value}
        showLabel={true}
        label={label}
        fullWidth
        placeholder={selectWalletLabel}
        sx={{ mb: "16px", ...sx }}
        disableClearable
        renderOption={(_props, option, rest) => {
          return (
            <>
              <li {..._props}>{option.name}</li>
              {rest?.index === walletData.length - 1 && (
                <>
                  <CustomDivider
                    className="currency-divider"
                    sx={{ margin: "0px -10px" }}
                  />
                  <Text
                    size={14}
                    className="pointer-cursor"
                    font="semibold"
                    variant="subtitle1"
                    sx={{
                      color: "#2A67FF",
                      m: "17px 0 7px 14px",
                    }}
                    onClick={() => {
                      setFieldValue(name, "other");
                      setIsOtherWallet(true);
                      setOpen(false);
                    }}
                  >
                    {otherWallet}
                  </Text>
                </>
              )}
            </>
          );
        }}
      />

      {isOtherWallet && (
        <Input
          fullWidth={true}
          label={otherWallet}
          placeholder={enterWalletName}
          name="other_wallet"
          onChange={(e) => setFieldValue("other_wallet", e.target.value)}
          sx={{ mb: from === "feedback" ? "16px" : 0 }}
          value={otherWalletValue}
          inputProps={{
            maxLength: 250,
          }}
        />
      )}
    </>
  );
};

export default WalletsWithOtherInput;
