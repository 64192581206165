import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import classNames from 'classnames'
import './tooltip.scss'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ maxwidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxwidth,
    wordBreak: 'break-word',
  },
}))

export const CustomTooltip = ({
  text,
  position,
  maxWidth,
  className,
  ...props
}) => {
  return (
    <CustomWidthTooltip
      className={classNames('tooltip-wrapper', className)}
      title={text ? text : ''}
      placement={position}
      maxwidth={maxWidth}
      arrow
      {...props}
    >
      {props.children}
    </CustomWidthTooltip>
  )
}

export const propTypes = {
  position: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
  text: PropTypes.element.isRequired,
  children: PropTypes.element,
  maxwidth: PropTypes.number,
}

const defaultProps = {
  position: 'top',
  maxwidth: 300,
}

CustomTooltip.propTypes = propTypes
CustomTooltip.defaultProps = defaultProps
