import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import classNames from "classnames";
import "./tag.scss";

export const Tag = ({ text, variant, className, ...props }) => {
  return (
    <Chip
      className={classNames("tag-wrapper", variant, className)}
      label={text}
      {...props}
    />
  );
};

export const propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    "default",
    "warning",
    "success",
    "primary",
    "success-res",
    "error",
    "error-primary",
  ]),
};

const defaultProps = {
  variant: "default",
};

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;
