import CommonCheckoutActions from "./CommonCheckoutActions";
import { errorGif } from "./images";
import { useLocation } from "react-router-dom";

const DeactivatedLink = () => {
  const url = useLocation();
  const getModule = url.pathname.split("/")[1];

  return (
    <CommonCheckoutActions
      action="deactivated"
      actionMainText="cannotOpenLink"
      actionGif={errorGif}
      actionSubText={
        getModule === "withdraw"
          ? "withdrawLinkNoLongerActive"
          : "ulrNoLongerActive"
      }
    />
  );
};

export default DeactivatedLink;
