import React from "react";
import { Box } from "@mui/material";
import {
  accountLoader,
  headerLoader,
  wholeQrSkeleton,
} from "../CommonSkeletonComponent";

const WebSkeleton = () => {
  return (
    <Box className="latest-web-design-wrapper">
      <Box
        component="div"
        className="left-side-section"
        sx={{
          padding: "0 70px 0 0",
          alignItems: "flex-end",
          borderRight: "1px solid #F7FAFC",
        }}
      >
        <Box className="left-container" width="442px">
          {accountLoader()}
          {headerLoader({})}
        </Box>
      </Box>
      <Box component="div" padding="0 0 0 70px" className="right-side-section">
        <Box className="right-container-box" width="442px">
          {wholeQrSkeleton({ size: 398 })}
        </Box>
      </Box>
    </Box>
  );
};

export default WebSkeleton;
