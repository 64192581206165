import PropTypes from "prop-types";
import classNames from "classnames";
import "./input-selection-box.scss";
import { CustomTooltip } from "../Tooltip/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import React, { useState } from "react";
import { Box } from "@mui/system";
import { InputBase } from "@mui/material";

const InputSelectionBox = ({
    text,
    variant,
    copyText,
    customWidth,
    customHeight,
    disabled,
    className,
    multiline,
    ...props
}) => {
    const [copied, setCopied] = useState(false);
    const SelectionInputElement = React.forwardRef((_props, ref) => {
        return (
            <Box {..._props} ref={ref}>
                <InputBase
                    value={text}
                    className={classNames(
                        variant,
                        disabled && "disabled",
                        copied && "copied-background"
                    )}
                    sx={{ width: customWidth, height: customHeight }}
                    onFocus={(event) => !copyText && event.target.select()}
                    readOnly
                    multiline={multiline}
                />
            </Box>
        );
    });

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const handleEnterKeyPress = (event) => {
        if (event.keyCode === 13) {
            event.currentTarget.click();
        }
    };

    return (
        <CopyToClipboard
            text={copyText ? text : null}
            onCopy={copyText && !disabled && handleCopy}
        >
            <CustomTooltip
                arrow={false}
                open={copied}
                placement={"top"}
                text={
                    <>
                        <CheckCircleSharpIcon />
                        <span className="copied-clipboard-text">Copied</span>
                    </>
                }
            >
                <SelectionInputElement
                    {...props}
                    tabIndex={0}
                    onKeyDown={handleEnterKeyPress}
                    className={classNames("input-selection-box", className)}
                />
            </CustomTooltip>
        </CopyToClipboard>
    );
};

export const propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["square", "round"]),
    copyText: PropTypes.bool,
    disabled: PropTypes.bool,
    customWidth: PropTypes.number,
    customHeight: PropTypes.number,
    multiline: PropTypes.bool,
};

const defaultProps = {
    variant: "square",
    copyText: false,
    disabled: false,
    customWidth: 337,
    customHeight: 29,
    multiline: false,
};

InputSelectionBox.propTypes = propTypes;
InputSelectionBox.defaultProps = defaultProps;

export default InputSelectionBox;
