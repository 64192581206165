import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import "./alert-message.scss";

const AlertMessage = forwardRef(function AlertMsg(
  { severity, className, message, ...props },
  ref
) {
  let icon;
  switch (severity) {
    case "error":
      icon = <ErrorIcon className="error-icon" />;
      break;
    case "success":
      icon = <CheckCircleIcon className="success-icon" />;
      break;
    case "warning":
      icon = <WarningIcon className="warning-icon" />;
      break;
    default:
      break;
  }
  return (
    <Alert
      className={classNames("alert-wrapper", className)}
      severity={severity}
      icon={icon}
      ref={ref}
      {...props}
    >
      {" "}
      {message}{" "}
    </Alert>
  );
});

const propTypes = {
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

AlertMessage.propTypes = propTypes;

export default AlertMessage;
