import "./auto-complete.scss";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import Box from "@mui/material/Box";
import Label from "../Label/Label";

export const CustomAutoComplete = ({
  showLabel,
  error,
  label,
  placeholder,
  options,
  customClass,
  sx,
  customIcon = false,
  allowedAutoComplete = false,
  ...props
}) => {
  const classes = classNames("auto-complete", customClass);
  return (
    <Box className={classes} sx={sx || {}}>
      {showLabel && <Label>{label}</Label>}
      <Autocomplete
        options={options}
        renderInput={(params) => {
          const textProps = {
            ...params,
            inputProps: {
              ...params.inputProps,
              autoComplete: allowedAutoComplete ? "none" : "off",
            },
          };
          return (
            <TextField
              error={error}
              name={props.name}
              placeholder={placeholder}
              {...textProps}
            />
          );
        }}
        popupIcon={
          <>
            {customIcon ? (
              customIcon
            ) : (
              <KeyboardArrowDownIcon
                style={{
                  width: 21,
                  height: 25,
                  color: props.disabled ? "rgba(0,0,0,0.26)" : "#848B9E",
                }}
              />
            )}
          </>
        }
        {...props}
      />
    </Box>
  );
};

export const propTypes = {
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  label: "Input Label",
  showLabel: true,
  disabled: false,
  error: false,
  options: [
    { label: "option1", value: "option1" },
    { label: "option2", value: "option2" },
    { label: "option3", value: "option3" },
  ],
};

CustomAutoComplete.propTypes = propTypes;
CustomAutoComplete.defaultProps = defaultProps;
