import Link from "@mui/material/Link";
import "./link.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

export const CustomLink = ({
  href,
  size,
  bold,
  target,
  withIcon,
  className,
  alignIcon,
  color,
  ...props
}) => {
  const classes = classNames(bold ? `bold ${size}` : size, className);
  const customStyle = alignIcon
    ? { display: "flex", alignItems: "center" }
    : {};

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      props.onClick && props.onClick();
    }
  };

  let linkStyle = {};
  if (alignIcon == "start") {
    linkStyle = { marginLeft: "6px" };
  } else if (alignIcon == "end") {
    linkStyle = { marginRight: "6px" };
  }

  return (
    <Link
      onKeyUp={handleKeyPress}
      {...props}
      style={{ ...props.style, ...customStyle }}
      target={target}
      href={href}
      className={classes}
      underline="none"
      color={color}
      tabIndex={0}
    >
      {alignIcon == "start" && withIcon}
      <span style={linkStyle}>{props.children}</span>
      {alignIcon == "end" && withIcon}
    </Link>
  );
};

export const propTypes = {
  href: PropTypes.string,
  size: PropTypes.string,
  bold: PropTypes.bool,
  target: PropTypes.string,
  withIcon: PropTypes.any,
  alignIcon: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  bold: false,
  size: "large",
  color: "#2a67ff",
  alignIcon: "",
};

CustomLink.propTypes = propTypes;
CustomLink.defaultProps = defaultProps;
