import React from "react";
import { Box } from "@mui/material";
import {
  accountLoader,
  headerLoader,
  wholeQrSkeleton,
} from "../CommonSkeletonComponent";

const MobileSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" className="latest-mobile-design">
      <Box className="header-mobile-skeleton ">
        {accountLoader()}
        <Box className="content-center">
          {headerLoader({
            classes: "content-center",
            sx: { flexDirection: "column" },
          })}
        </Box>
      </Box>
      <Box padding="0 12px 24px" className="margin-top30">
        {wholeQrSkeleton({ size: 325 })}
      </Box>
    </Box>
  );
};

export default MobileSkeleton;
