import { Router } from "react-router-dom";
import history from "@speed/common/src/components/history";
import Main from "./container/Main";
import "@speed/common/src/components/core.scss";
import "./assets/styles/main.scss";
import { StyledEngineProvider } from "@mui/material/styles";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Router history={history}>
        <Main />
      </Router>
    </StyledEngineProvider>
  );
};

export default App;
