import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import './divider.scss';

export const CustomDivider = (props) => {
    return (
        <Divider {...props}>{props?.text}</Divider>
    )
}

const propTypes = {
    text: PropTypes.string
}

CustomDivider.propTypes = propTypes;

export default CustomDivider;