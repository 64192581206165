import React, { useState } from "react";
import BackgroundImage from "@speed/common/src/components/BackgroundImage";
import Text from "@speed/common/src/components/Text/Text";
import { Box, Grid } from "@mui/material";
import Translation from "@speed/common/src/components/Translation";
import { underMaintenance } from "@speed/common/src/components/images";
import { CustomLink } from "./Link/Link";
import { speed } from "./messages";
import LockIcon from "@mui/icons-material/Lock";
import { reportIssueTextComponent } from "./constants";
import CommonReportFeedbackModal from "./CommonReportFeedbackModal";
import {
  reasonLabel,
  reasonPlaceholder,
  reportIssue,
  submitIssueMsg,
} from "../messages";
import { useLocation } from "react-router-dom";

const UnderMaintenancePage = () => {
  const [reportIssueModal, setReportIssueModal] = useState(false);
  const customMessage = useLocation()?.state?.message;
  const fontStyle = {
    fontFamily: "system-ui !important",
  };
  const style = { opacity: 0.5, ...fontStyle };

  return (
    <Box className="under-maintenance-wrapper">
      <BackgroundImage />
      <Grid container className="common-grid-wrapper under-maintenance-grid">
        <img src={underMaintenance} className="under-maintenance-image" />
        {!customMessage && (
          <Text
            variant="h2"
            font="semibold"
            size={20}
            align="center"
            className="default-text under-maintenance-header"
          >
            <Translation value="underMaintenanceHeader" />
          </Text>
        )}

        <Text
          variant="subtitle1"
          font="medium"
          size={16}
          align="center"
          className="default-text"
          sx={{ maxWidth: "635px", mt: customMessage && "70px" }}
        >
          {customMessage || <Translation value="underMaintenanceSubHeader" />}
        </Text>
        <Box className="sub-header" mt="15px">
          {reportIssueTextComponent({
            setReportIssueModal,
            shouldModalOpen: true,
          })}
        </Box>

        <Grid
          container
          className="checkout-link-footer under-maintenance-footer"
          sx={{ padding: "10px 0" }}
        >
          <Text sx={{ ...style }} size={14} withIcon="start">
            <CustomLink
              className="checkout-page-text secured-by-text footer-text-color"
              size="small"
              href={process.env.REACT_APP_PROMO_WEBSITE}
              target="_blank"
            >
              <LockIcon />
              <Translation value="securedBy" />
              <Text
                className="speed-logo"
                size={14}
                font="semibold"
                variant="inline"
              >
                &nbsp;{speed}
              </Text>
            </CustomLink>
          </Text>
          <Text sx={style} size={12} className="mt-25">
            <CustomLink
              className="checkout-page-text  footer-text-color"
              href={process.env.REACT_APP_PROMO_WEBSITE + "terms"}
              target="_blank"
              size="small"
            >
              <Translation value="terms" />
              <span className="dot-style"> &middot; </span>
            </CustomLink>
            <CustomLink
              className="checkout-page-text footer-text-color"
              href={process.env.REACT_APP_PROMO_WEBSITE + "privacy"}
              target="_blank"
              size="small"
            >
              <Translation value="privacy" />
            </CustomLink>
          </Text>
        </Grid>
      </Grid>
      <CommonReportFeedbackModal
        commentInputLabel={reasonLabel}
        commentInputPlaceholder={reasonPlaceholder}
        from="under-maintenance"
        modalTitle={reportIssue}
        openModal={reportIssueModal}
        setOpenModal={setReportIssueModal}
        successMsg={submitIssueMsg}
        visibleInPaymentPage={true}
        sourceId="NA"
        businessName="NA"
        targetCurrency="NA"
      />
    </Box>
  );
};

export default UnderMaintenancePage;
