import { callAPIInterfaceCommon } from "@speed/common/src/components/constants";
import { useEffect, useState } from "react";
import history from "@speed/common/src/components/history";
import { Grid, Box } from "@mui/material";
import classNames from "classnames";
import WebSkeleton from "@speed/common/src/components/LatestWebDesign/WebSkeleton";
import "@speed/common/src/components/LatestWebDesign/latest-web-design.scss";
import MobileSkeleton from "@speed/common/src/components/LatestMobileDesign/MobileSkeleton";

const Buy = (props) => {
  const [isLoading, setLoading] = useState(true);

  const getPaymentPage = () => {
    const paymentLinkId = props.match && props.match.params.id;
    setLoading(true);
    callAPIInterfaceCommon(
      "GET",
      `/payment-links/payment-page/${paymentLinkId}`
    )
      .then((res) => {
        if (res.status === "deactivated") {
          history.push("/error/deactivated-link");
          setLoading(false);
        } else {
          if (res.url) window.location.href = res.url;
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          history.push({
            pathname: "/error/not-found-link",
            state: {
              from: "checkout",
            },
          });
        } else {
          history.push("/error/under-maintenance");
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    getPaymentPage();
  }, []);

  const renderWebVersion = () => {
    return (
      <Box className="checkout-web-preview">
        <WebSkeleton />
      </Box>
    );
  };

  const renderMobileVersion = () => {
    return (
      <Box
        className={classNames("checkout-page-box", "checkout-mobile-preview")}
      >
        <Grid className="mobile-view-branding">
          <MobileSkeleton />
        </Grid>
      </Box>
    );
  };

  return (
    isLoading && (
      <>
        {renderWebVersion()}
        {renderMobileVersion()}
      </>
    )
  );
};

export default Buy;
